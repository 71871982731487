




























import {SavedJobService} from '../../../../../../Service/SavedJobService';
import Btn from '../../../../../Misc/Btn/Btn.vue';

export default {
    name: 'SavedJobsButton',
    components: {Btn},
    props: {
        icon: String,
        iconPosition: String
    },
    computed: {
        count(): number
        {
            return SavedJobService.getSavedJobs().size;
        }
    }
};
