



































import {isBrowser} from '@labor-digital/helferlein/lib/Environment/isBrowser';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {getDocumentHeight} from '@labor-digital/helferlein/lib/Ui/getDocumentHeight';
import {getScrollPos} from '@labor-digital/helferlein/lib/Ui/getScrollPos';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import ComponentProxyAwareMixin from '../../../../Mixin/ComponentProxyAwareMixin';
import {LanguageSwitch} from '../../../../Service/LanguageSwitch';
import {MenuService} from '../../../../Service/MenuService';
import {SavedJobService} from '../../../../Service/SavedJobService';
import {ScrollThrottle} from '../../../../Service/ScrollThrottle';
import Btn from '../../../Misc/Btn/Btn.vue';
import SavedJobsButton from './SpecialButtons/SavedJobsButton/SavedJobsButton.vue';
import FindJobMenu from './Types/FindJob/FindJobMenu.vue';
import SavedJobsMenu from './Types/SavedJobs/SavedJobsMenu.vue';

export default {
    name: 'FixedActionButtons',
    components: {Btn},
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: {
            required: true
        } as PageContext
    },
    data()
    {
        return {
            scrollThrottle: null,
            scrollPos: 0,
            threshold: {
                desktop: {
                    banner: 690,
                    noBanner: 150
                },
                mobile: {
                    banner: 210,
                    noBanner: 80
                }
            }
        };
    },
    computed: {
        /**
         * Returns the button definitions, filtered by their "displayCondition" callback
         */
        buttons(): Array<PlainObject>
        {
            return [
                {
                    key: 'findJobs',
                    title: this.$t('findJobModule.form.header.mobile'),
                    icon: 'briefcase',
                    displayCondition: (): boolean => {
                        return MenuService.isForApplicants() || !LanguageSwitch.isMainLanguage();
                    },
                    action: () => {
                        this.context.store.set(
                                AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT,
                                FindJobMenu);
                    }
                },
                {
                    key: 'savedJobs',
                    title: this.$t('jobSearch.list.savedJobs'),
                    icon: 'heart',
                    component: SavedJobsButton,
                    displayCondition: (): boolean => {
                        return (MenuService.isForApplicants() || !LanguageSwitch.isMainLanguage())
                               && SavedJobService.getSavedJobs().size > 0;
                    },
                    action: () => {
                        this.context.store.set(
                                AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT,
                                SavedJobsMenu);
                    }
                }
                // {
                //     key: 'chatBot',
                //     title: this.$t('chatBot.title'),
                //     icon: 'chat',
                //     displayCondition: (): boolean => LanguageSwitch.isMainLanguage(),
                //     action: () => {
                //         this.context.store.set(
                //                 AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT,
                //                 ChatBot);
                //     }
                // }
            ];
        },
        /**
         * Returns true if we should use the "mobile" thresholds
         */
        isMobile(): boolean
        {
            return this.context.store.get(AppStoreKeys.BREAKPOINT).is('<', 'md');
        },
        /**
         * Returns true if the page has a banner of some sort
         */
        hasBanner(): boolean
        {
            if (this.context.data.get('media', []).length > 0) {
                return true;
            }
            return this.context.data.get('mediaVideo', null) !== null;
        },
        /**
         * Returns true if this component should be rendered
         */
        isRendered(): boolean
        {
            // Don't show this in SSR
            if (!isBrowser()) {
                return false;
            }

            // Check if we have stuff to show
            return this.buttons.length > 0;
        },
        /**
         * Returns true if a sidebar is open and we should hide the buttons...
         */
        isSidebarOpen(): boolean
        {
            return this.context.store.get(AppStoreKeys.SIDEBAR_DIRECTION, null) !== null;
        },
        /**
         * Returns true if the buttons should be visible a.k.a should slide into the viewport
         */
        isVisible(): boolean
        {
            // Check if the sidebar is open
            if (this.isSidebarOpen) {
                return false;
            }

            // Select the correct threshold
            let threshold = this.isMobile ? this.threshold.mobile : this.threshold.desktop;
            threshold = this.hasBanner ? threshold.banner : threshold.noBanner;

            // Fallback for ultra-short pages
            const docHeight = getDocumentHeight();
            if (docHeight <= 1500 && this.scrollPos > docHeight * 0.1) {
                return true;
            }

            return this.scrollPos > threshold;
        },
        /**
         * Returns the list of css classes applied to the component
         */
        classes()
        {
            return {
                'fixedActionButtons--shown': this.isVisible
            };
        }
    },
    methods: {
        setScrollPos()
        {
            this.scrollPos = getScrollPos();
        }
    },
    mounted(): void
    {
        this.scrollThrottle = new ScrollThrottle(this.setScrollPos);
        this.scrollThrottle.start();
    },
    beforeDestroy()
    {
        this.scrollThrottle.stop();
    }
};
