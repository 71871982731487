




































import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import JobLocationFilter from '../../../../../Job/JobFilter/Type/JobLocationFilter/JobLocationFilter.vue';
import JobQueryFilter from '../../../../../Job/JobFilter/Type/JobQueryFilter/JobQueryFilter.vue';
import Btn from '../../../../../Misc/Btn/Btn.vue';
import FindJobModule from '../../../../MainMenu/Module/FindJobModule/FindJobModule.vue';

export default {
    name: 'FindJobMenu',
    components: {JobLocationFilter, JobQueryFilter, Btn},
    extends: FindJobModule,
    props: {
        context: null as PageContext
    }
};
