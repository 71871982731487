

























import {asArray} from '@labor-digital/helferlein/lib/FormatAndConvert/asArray';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {AppStoreKeys} from '../../../../../../AppStoreKeys';
import {Job} from '../../../../../../Interface/Job';
import {SavedJobService} from '../../../../../../Service/SavedJobService';
import JobItemList from '../../../../../Job/JobItemList/JobItemList.vue';

export default {
    name: 'SavedJobsMenu',
    components: {JobItemList},
    computed: {
        jobs(): Array<Job>
        {
            return asArray(SavedJobService.getSavedJobs());
        }
    },
    watch: {
        jobs: function (value) {
            // Close the menu if there are no jobs left
            if (isEmpty(value)) {
                this.$root.appContext.store.set(AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT, null);
            }
        }
    }
};
