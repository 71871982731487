var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isRendered
    ? _c(
        "div",
        { staticClass: "fixedActionButtons", class: _vm.classes },
        [
          _c(
            "transition-group",
            { attrs: { name: "slideIn", tag: "div" } },
            _vm._l(_vm.buttons, function (button) {
              return button.displayCondition()
                ? _c(
                    button.component !== undefined ? button.component : "btn",
                    {
                      key: button.key,
                      tag: "component",
                      attrs: {
                        icon: button.icon,
                        identifier: button.key,
                        title: button.title,
                        "icon-position": "withoutLabel",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return button.action.apply(null, arguments)
                        },
                      },
                    }
                  )
                : _vm._e()
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }